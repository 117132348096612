import { DateTime } from "luxon"
import RoomMember from "./RoomMember"
import axios from "axios"
import { funcName, Logger, onlyUnique } from "packs/common"
import Util from "packs/utils/Util"
import AvailableScheduleTag from "./AvailableScheduleTag"
import Event from "./Event"
import NotificationControl from "packs/utils/NotificationControl"
import CalendarUtil from "packs/utils/CalendarUtil"

interface ConflictInfo {
    did_check: boolean
    events: Event[]
    is_conflict: boolean
}

export default class PossibleDate {
    constructor(
        public format: string,
        public jp_format: string,
        public date_format: string,
        public start_time: number,
        public type: string,
        public short_format: string, // 06121200
        public start_hour: number,
        public start_min: number,
        public duration: number,
        public selected: boolean,
        public wday: number,
        public ok_attendees: any[],
        public ng_attendees: any[],
        public unknown_attendees: any[],
        public ok_groups: string[],
        public is_conflict: boolean,
        public priority: number,
        public ok_room_member_ids: string[],
        public conflict_info: ConflictInfo,
        public created_at: number,
        public updated_at: number,
        public id: string,
        public tz_format: string, // jp_formatと同様
        public tz_date_format: string, // 0629 date_formatと同様
        public tz_from_utc: string,
        public booked_num: number,
        public max_bookings_num: number,
        public is_group_event: boolean,
        public date: string
    ) {}

    // static fetchFromJson(datesJson: any): [PossibleDate[], string[]] {
    static fetchFromJson(datesJson: any): { [key: string]: any } {
        let possibleDates = []
        let jp_formats = []

        // Logger(`arrOfGroups: ${datesJson}`)
        for (const date of datesJson) {
            const _date: PossibleDate = date
            const fmt = _date.jp_format
            possibleDates.push(_date)
            jp_formats.push(fmt)
        }
        // return [possibleDates, jp_formats]
        return { possible_dates: possibleDates, jp_formats: jp_formats }
    }

    static toOptionalDates(pds: PossibleDate[]): Event[] {
        if (Util.isBlank(pds)) return []

        let ops = []
        for (let pd of pds) {
            const op = PossibleDate.toEvent(pd)
            ops.push(op)
        }
        return ops
    }

    static toEvent(pd: PossibleDate): Event {
        const time = DateTime.fromSeconds(pd.start_time)
        let ev = Event.createOptional(`optional_add`, time, pd.duration)
        return ev
    }

    static createFromStartTime(startTime: number, duration: number) {
        let st = DateTime.fromSeconds(startTime)
        let wday = st.weekday
        if (wday >= 7) wday -= 7

        let pd = new PossibleDate(
            st.toFormat("yyyy-MM-dd-HH:mm"),
            CalendarUtil.toJpFormat(startTime, duration),
            st.toFormat("MMdd"),
            startTime,
            `optional_add`,
            st.toFormat("MMddHHmm"),
            st.hour,
            st.minute,
            duration,
            true,
            wday,
            null,
            null,
            null,
            null,
            false,
            0,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            st.toFormat("MMdd")
        )
        return pd
    }

    /**
     * サーバーに送信するOptionalDateの追加日時をフォーマット化します。
     * @param addDates
     * @return {string: hash[]} key=dateString, op = { duration: pd.duration, start_time: pd.start_time }
     */
    static toFormat(addDates): any {
        let ops = {}
        for (let _op of addDates) {
            let op: any = _op
            let date: string = DateTime.fromSeconds(op.start_time).toFormat(`MMdd`)
            let arr = ops[date] || []
            arr.push(op)
            ops[date] = arr
        }
        return ops
    }

    /**
     * 作成済みのAstagから表示できる候補を取得してきます。
     * @param astag [AvailableScheduleTag]
     */
    static getPossibleDates(astag: AvailableScheduleTag, duration: number): Promise<any> {
        if (!duration) duration = 60
        const params = { id: astag.id, duration: duration }
        Logger(`PossibleDate.${funcName()} post PD possibleDates from Remote::::`)

        return axios
            .post(`${Util.prefixUrl}/available_schedule_tags/possible_dates`, params)
            .then(result => {
                const possibles = result.data.possible_dates
                const selected_possibles = result.data.selected_possible_dates
                const total_num = result.data.total_pds_num

                let pdm = {}
                if (!possibles) return pdm
                Logger(`PossibleDate.${funcName()} ***********get this.pd: ${Util.output(possibles)}`)

                let format = PossibleDate.fetchFromJson(possibles)
                pdm = format.possible_dates
                let selected_pdm = PossibleDate.fetchFromJson(selected_possibles || [])

                // jp_formatsのみを返します。
                return { pdm, selected_pdm: selected_pdm.possible_dates, total_num }
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static isSame(pd1, pd2) {
        return pd1.start_time == pd2.start_time && pd1.duration == pd2.duration
    }

    static checkPdIndex(pds: PossibleDate[], pd: PossibleDate): number {
        let num = -1
        if (!pds || pds.length == 0) return num
        if (!pd) return num

        num = pds.findIndex(_pd => PossibleDate.isSame(pd, _pd))

        Logger(`PossibleDate.${funcName()} num: ${num}`)
        return num
    }

    static checkPdIndexType(pds: PossibleDate[], num: number) {
        if (!pds) return
        if (num == 0) {
            return "first"
        } else if (num == pds.length - 1) {
            return "last"
        } else {
            return ``
        }
    }

    static changeForPreview(astag: AvailableScheduleTag) {
        if (!astag) return
        let ops = { ...astag.optional_schedules }
        if (Util.isPresent(ops)) {
            Object.entries(ops).forEach(([date, events]) => {
                let _events: PossibleDate[] = events as any
                let evs = []
                for (let ev of _events) {
                    // ev.systemUpdatedAt = Util.getSec() + ev.start_time
                    ev.selected = false
                    ev.type = `optional`
                    evs.push(ev)
                }
                ops[date] = evs
                // this.rcm.astag.optional[date] = evs
            })
        }
        return ops
    }

    /**
     * pdsから月ごとの可能な日付を取得します。
     * @param pds
     * @param tzDic
     * @returns
     */
    static getAvailableCalendarsFromPds(pds: PossibleDate[], tzDic: any = null): any[] {
        Logger(`PossibleDate.${funcName()} tzDic:${Util.output(tzDic)}`)

        let possibleDatekeys = [] // ["0614", "0615", "0616"]
        let thisMonth = DateTime.local().month
        let monthPlus1 = thisMonth + 1 > 12 ? thisMonth + 1 - 12 : thisMonth + 1
        let monthPlus2 = thisMonth + 2 > 12 ? thisMonth + 2 - 12 : thisMonth + 2
        let monthPlus3 = thisMonth + 3 > 12 ? thisMonth + 3 - 12 : thisMonth + 3
        let monthPlus4 = thisMonth + 4 > 12 ? thisMonth + 4 - 12 : thisMonth + 4
        let thisMonthDates = []
        let nextMonthDates = []
        let monthDatesPlus2 = []
        let monthDatesPlus3 = []
        let monthDatesPlus4 = []
        let cannotFindTZformat = false
        let now = DateTime.local().toSeconds()
        for (let _pd of pds) {
            let _month
            let _day
            if (Util.isPresent(tzDic) && Util.isPresent(_pd.tz_date_format)) {
                _month = Number(_pd.tz_date_format.slice(0, 2))
                _day = Number(_pd.tz_date_format.slice(2, 4))
            } else {
                _month = Number(_pd.short_format.slice(0, 2))
                _day = Number(_pd.short_format.slice(2, 4))
                cannotFindTZformat = true
            }
            if (_pd.start_time) {
                let dt = DateTime.fromSeconds(_pd.start_time)

                if (dt && !possibleDatekeys.includes(`${dt.toFormat(`yyyyMMdd`)}`)) {
                    possibleDatekeys.push(`${dt.toFormat(`yyyyMMdd`)}`)
                }
            }

            // Logger(`pd: ${_pd.short_format}`)
            if (thisMonth == _month) {
                //今月
                thisMonthDates.push(_day)
            } else if (monthPlus1 == _month) {
                nextMonthDates.push(_day)
            } else if (monthPlus2 == _month) {
                monthDatesPlus2.push(_day)
            } else if (monthPlus3 == _month) {
                monthDatesPlus3.push(_day)
            } else if (monthPlus4 == _month) {
                monthDatesPlus4.push(_day)
            }
        }

        let monthlyPossibleDates = [
            thisMonthDates.filter(onlyUnique),
            nextMonthDates.filter(onlyUnique),
            monthDatesPlus2.filter(onlyUnique),
            monthDatesPlus3.filter(onlyUnique),
            monthDatesPlus4.filter(onlyUnique),
        ]
        return [monthlyPossibleDates, cannotFindTZformat, possibleDatekeys]
    }

    /**
     *
     * @param offset [number] -8, +3.5
     * @returns [number] -8, +3:30
     */
    static toTimezoneUTCFormat(offset: number): string {
        if (offset % 1 == 0) {
            // 整数
            return `${offset >= 0 ? `+` : ``}${offset}`
        }
        let inte = this.integerPart(offset)
        let dec = this.decimalPart(offset)
        let str = `${offset >= 0 ? `+` : `-`}${inte}:${dec * 60}`
        Logger(`PossibleDate.${funcName()} ret:${str}`)
        return str
    }

    static integerPart(num) {
        return num >= 0 ? Math.floor(num) : Math.ceil(num)
    }

    static decimalPart(num): number {
        let decPart = num - (num >= 0 ? Math.floor(num) : Math.ceil(num))
        // return decPart.toFixed(decDigits) as number;
        return decPart
    }

    static setTimeZoneWithFormat(pd: PossibleDate, tzDic: any): PossibleDate {
        let st = pd.start_time
        let offsetStr = PossibleDate.toTimezoneUTCFormat(tzDic.diff)
        let tz_st = DateTime.fromSeconds(st).setZone(`UTC${offsetStr}`)
        let tz_et = tz_st.plus({ minute: pd.duration })
        pd.tz_date_format = tz_st.toFormat("MMdd")
        pd.tz_from_utc = offsetStr
        let stFormat = tz_st.toFormat("MM/dd/yyyy, HH:mm")
        let etFormat = tz_et.toFormat("HH:mm")
        pd.tz_format = `${stFormat}~${etFormat}`
        return pd
    }

    /**
     *
     * @param pd [PossibleDate]
     * @param dateId [string] "0614"
     * @param tzDic [TimezoneDic] タイムゾーン
     */
    static isSameDateId(pd: PossibleDate, dateId: string, tzDic: any) {
        if (tzDic && pd.tz_date_format) {
            return pd.tz_date_format == dateId
        } else {
            return pd.date_format == dateId
        }
    }

    static createDefaultConflictInfo(evs = []): ConflictInfo {
        const ci: ConflictInfo = {
            did_check: true,
            events: evs, // Event型の配列を適切に定義する必要があります
            is_conflict: true,
        }
        return ci
    }
}
