import axios from "axios"
import { DateTime, Interval } from "luxon"
import { Logger } from "packs/common"
// import qs from "qs"
// import isMobile from "ismobilejs"
import Appointment from "packs/models/Appointment"
import Util from "./Util"
import RoomMember from "packs/models/RoomMember"
import DateTag from "packs/models/DateTag"
import RoomSetting from "packs/models/RoomSetting"
import SelectUtil from "./SelectUtil"
import Notice from "packs/models/Notice"
import NotificationControl from "./NotificationControl"
// declare var ics: any
// const ics = require("ics")
// const { writeFileSync } = require("fs")

//  便利系を記載.
export default class CalendarUtil {
    static createEvent(appo: Appointment, publicId: string, attendees: RoomMember[]) {
        // let start = DateTime.fromSeconds(st)
        // let stArr = [start.year, start.month, start.day, start.hour, start.minute]
        // Logger(`create event: ${stArr}`)
        const str = this.toIcsFormat(appo, publicId, attendees)
        let blob = new Blob([str], { type: "text/calendar" })
        let link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = `waaq_event_${appo.start_time}.ics`
        link.click()
    }

    static toIcsFormat(appo: Appointment, publicId: string, attendees: RoomMember[]): string {
        const now = DateTime.local().toUTC()
        const startTime = DateTime.fromSeconds(appo.start_time).toUTC()
        // let attStr = ``
        let attArray = []
        for (let att of attendees) {
            let attStr = ``
            attStr += `${att.name}`
            if (att.short_name) attStr += `(${att.short_name})`
            attArray.push(attStr)
        }
        // const endTime = startTime.plus({ minutes: appo.duration })
        return `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
PRODID:waaqlink/waaq.jp
METHOD:PUBLISH
X-PUBLISHED-TTL:PT${appo.duration}M
BEGIN:VEVENT
UID:${this.calendarFormat(now)}Z
SUMMARY:${appo.name}
DTSTAMP:${this.calendarFormat(now)}Z
DTSTART:${this.calendarFormat(startTime)}Z
DESCRIPTION:出席予定者: ${attArray.join(`,`)}\\n \\n${this.getLocationInfoForDescription(appo, true).replace(
            /\n/g,
            `\\n`
        )}\\n調整ページID: ${publicId}
X-MICROSOFT-CDO-BUSYSTATUS:FREE
DURATION:PT${appo.duration}M
LOCATION:${this.getLocationName(appo)}
ORGANIZER:mailto:
END:VEVENT
END:VCALENDAR`
    }

    static calendarFormat(time: DateTime) {
        const date = time.toFormat(`yyyyMMdd`)
        const hourmin = time.toFormat(`HHmm`)
        return `${date}T${hourmin}00`
    }

    static calendarMSFormat(time: DateTime) {
        const date = time.toFormat(`yyyy-MM-dd`)
        const hourmin = time.toFormat(`HH:mm`)
        return `${date}T${hourmin}:00`
    }

    static getLocationName(appo: Appointment): string {
        if (appo.is_online_meeting) {
            let onlineDic = appo.online_meeting
            if (onlineDic) {
                return onlineDic.online_meeting_url
            }
        }
        return appo.location_name
    }

    static getLocationInfoForDescription(appo: Appointment, useLineBreak = true): string {
        let text = ``
        if (appo.is_online_meeting) {
            text = `オンライン会議を予定しています。`
            text += useLineBreak ? `\n` : `<br>`

            let onlineDic = appo.online_meeting
            if (onlineDic) {
                text += `出席者用URL:${useLineBreak ? `\n` : `<br>`}${onlineDic.online_meeting_url}${
                    useLineBreak ? `\n\n` : `<br>`
                }`
                if (onlineDic.online_meeting_id && onlineDic.online_meeting_password) {
                    text += `${useLineBreak ? `-------\n` : `<br>`}ID: ${onlineDic.online_meeting_id}${
                        useLineBreak ? `\n` : `  `
                    }ミーティングパスコード: ${onlineDic.online_meeting_password}${useLineBreak ? `\n-------` : `<br>`}`
                }
            }
        }
        if (Util.isBlank(text) && Util.isPresent(appo.location_name)) text += appo.location_name
        text += useLineBreak ? `\n\n` : `<br><br>`

        if (Util.isPresent(appo.memo)) text += appo.memo
        text += useLineBreak ? `\n\n` : `<br><br>`

        return text
    }

    static createGoogleEvent(appo: Appointment, publicId: string): string {
        const startTime = DateTime.fromSeconds(appo.start_time)
        const endTime = startTime.plus({ minutes: appo.duration })
        // Logger(`Googleカレンダー startTime: ${CalendarUtil.calendarFormat(startTime)}`)
        let params = {
            text: `${appo.name}`,
            dates: `${this.calendarFormat(startTime)}/${this.calendarFormat(endTime)}`,
            details: `${this.getLocationInfoForDescription(appo)}\n--------------------------\n調整ページID: [${publicId}]`,
        }
        if (appo.location_name) params["location"] = this.getLocationName(appo)
        const url = `http://www.google.com/calendar/render?action=TEMPLATE&${Util.serialize(params)}`
        return url
    }

    /**
     *
     * @param appo
     * @param publicId
     * @param type "office" or "outlook"
     */
    static createMSEvent(appo: Appointment, publicId: string, type: string): string {
        const startTime = DateTime.fromSeconds(appo.start_time)
        const endTime = startTime.plus({ minutes: appo.duration })
        const urlType = type == "office" ? `outlook.office` : `outlook.live`
        Logger(`カレンダー登録appo : ${Util.output(appo)}, ${startTime} ${this.calendarMSFormat(startTime)}`)
        let bodyText = this.getLocationInfoForDescription(appo, false)
        bodyText = Util.truncate(bodyText, 250).replace(/\n/g, `<br>`)

        let params = {
            subject: `${appo.name}`,
            startdt: `${this.calendarMSFormat(startTime)}`,
            enddt: `${this.calendarMSFormat(endTime)}`,
            location: `${appo.location_name ? `${this.getLocationName(appo)}` : ``}`,
            body: `${bodyText}<br>--------------------------<br>調整ページID: [${publicId}]`,
        }
        const url = `https://${urlType}.com/owa/?path=/calendar/action/compose&rru=addevent&${Util.serialize(params)}`
        Logger(`MS Calendar URL: ${url}`)
        return url
    }

    static toJpFormatDate(start_time: number) {
        if (!start_time) return
        let st = DateTime.fromSeconds(start_time)
        let wday = DateTag.weekNames()
        let weekday = st.weekday
        if (weekday >= 7) weekday -= 7

        return st.toFormat(`MM月dd日（${wday[weekday]}）`)
    }

    static toHourMin(start_time: number) {
        if (!start_time) return
        let st = DateTime.fromSeconds(start_time)

        return st.toFormat(`HH:mm`)
    }

    static toCalId(dt: DateTime): string {
        return dt.toFormat(`yyyyMM`)
    }

    // str [string] "202352"
    static toTimeFromWeekSpan(str: string): DateTime {
        let yearNumber = parseInt(str.slice(0, 4))
        let weekNumber = parseInt(str.slice(4, 6))
        const dt = DateTime.fromObject({
            weekYear: yearNumber,
            weekNumber: weekNumber,
            weekday: 7,
        })
        return dt
    }

    static toWeekSpan(dt: DateTime): string {
        // 日曜日を週の開始日とする
        const startOfWeek = dt.minus({ days: dt.weekday % 7 })
        return startOfWeek.toFormat(`kkkkWW`)
    }

    static toJpFormat(start_time: number, duration: number, includeYear = false) {
        if (!start_time) return
        if (!duration) duration = 0
        let st = DateTime.fromSeconds(start_time)
        let et = DateTime.fromSeconds(start_time + duration * 60)
        let wday = DateTag.weekNames()
        let weekday = st.weekday
        if (weekday >= 7) weekday -= 7
        let jpFormat = ``
        jpFormat += includeYear ? st.toFormat(`yyyy年`) : ``
        jpFormat += st.toFormat(`MM月dd日（${wday[weekday]}）HH:mm`)
        jpFormat += duration != 0 ? et.toFormat(`~HH:mm`) : ``
        return jpFormat
    }

    static readonly defaultMeetingTimeDic = {
        type: "1時間ごと",
        description: `00スタートの日程を表示します。`,
        time: `hourly`,
        number: 60,
    }

    /**
     * 提案する候補のpossible_datesの表示設定.
     */
    static readonly selectDisplayMeetingTimeArr = [
        {
            type: "15分ごと",
            description: `00/15/30/45スタートの日程を表示します。`,
            time: `every_15_min`,
            number: 15,
        },
        { type: "30分ごと", description: `00/30スタートの日程を表示します。`, time: `every_30_min`, number: 30 },
        { type: "1時間ごと", description: `00スタートの日程を表示します。`, time: `hourly`, number: 60 },
        {
            type: "奇数時間ごと",
            description: `00分で奇数時間にスタートの日程を表示します。`,
            time: `odd_hourly`,
            number: -1,
        },
        {
            type: "偶数時間ごと",
            description: `00分で偶数時間にスタートの日程を表示します。`,
            time: `even_hourly`,
            number: -1,
        },
        {
            type: "カスタム",
            description: `開始時間を曜日ごとに設定します。`,
            time: `custom`,
            number: -1,
        },
    ]

    static readonly selectDisplayPossibleDatesNum = [0, 1, 2, 3, 4, 5]

    static readonly selectAddEventTypes = [
        { type: "same_event", name: "オーナーと同一のイベントに招待して作成" },
        { type: "same_event_but_not_display_form", name: "オーナーと同一のイベントに招待するが、同席者はフォームで追加しない" },
        { type: "other_event", name: "オーナーと別イベントとして作成" },
        { type: "other_event_but_not_display_form", name: "オーナーと別イベントとして作成し、同席者はフォームで追加しない" },
    ]

    static getHolidayNum(startT: DateTime, endT: DateTime) {
        let interval = Interval.fromDateTimes(startT, endT)
        // 日程候補算出日は当日も含むため +1する
        let days = Math.ceil(interval.length(`days`)) + 1

        let nums = Array(days)
            .fill(0)
            .map((_, i) => i)
        let startW = startT.weekday
        Logger(`getHolidayNum: days: ${days}, startW: ${startW}, nums: ${Util.output(nums)}`)
        let weekendCounter = 0
        for (let d of nums) {
            let num = startW + d + weekendCounter
            if ([6].includes(num % 7)) {
                weekendCounter += 2
            } else if ([0].includes(num % 7)) {
                weekendCounter += 1
            }
        }
        Logger(`getHolidayNum: weekendCounter: ${weekendCounter}`)

        return weekendCounter
    }

    static getSpanDescriptionText(rs: RoomSetting): Promise<any[]> {
        let periodText = ``
        if (rs.available_period_type == `absolute`) {
            let startt = DateTime.fromSeconds(rs.available_period_start_at).toFormat(`MM月dd日`)
            let endt = DateTime.fromSeconds(rs.available_period_end_at).toFormat(`MM月dd日`)
            periodText += `${startt} ~ ${endt}`
            return Promise.resolve([periodText, []])
        }

        let skipType = rs.available_period_skip_type // none, weekend, weekend_and_holiday
        let skipTypeName = skipType != `none` ? SelectUtil.availablePeriodSkipTypes.find(t => t.type == skipType).name + ` ` : ``
        let startM = rs.available_period_start_min
        let startH = startM % 60 == 0 ? startM / 60 : null
        let startDay = startM % (60 * 24) == 0 ? startM / (60 * 24) : null
        let endM = rs.available_period_end_min
        let endDay = endM / (60 * 24)
        let availableMonths = []
        let availableWeeks = []
        let useBusinessDay = rs.use_business_day

        let params = { room_setting: rs }
        return axios
            .post(`${Util.prefixUrl}/rooms/available_period_preview`, params)
            .then(res => {
                Logger(`/rooms/available_period_preview: data: ${Util.output(res.data)}`)
                if (res.data.start_time) {
                    let startTimeNum = res.data.start_time
                    let startTime = DateTime.fromSeconds(startTimeNum)
                    let startFormat = startTime.toFormat(`MM月dd日`)
                    let endFormat = DateTime.fromSeconds(res.data.end_time).toFormat(`MM月dd日`)

                    periodText += `${skipTypeName}${startDay || startH || startM || 0}${
                        startDay ? (useBusinessDay ? `営業日` : `日`) : startH ? `時間` : `分`
                    }後 ~ ${endDay}${useBusinessDay ? `営業日` : `日`}後（${startFormat} ~ ${endFormat}）`
                    availableMonths = res.data.available_months
                    availableWeeks = res.data.available_weeks
                }

                return [periodText, availableMonths, availableWeeks]
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                // NotificationControl.showErrorMessage(err)
                return [``, null, null]
            })
    }

    static getHolidays(): Promise<any> {
        return axios.get(`${Util.prefixUrl}/calendars/holidays`).then(res => {
            if (Util.isPresent(res.data)) {
                return res.data.holidays
            }
            return null
        })
    }
}
